import top from "../../images/top.jpg";
import respire from "../../images/respire.png";
import respireLogo from "../../images/respire-logo.png";

export default function PublicHeader() {
  return (
    <div className="relative">
      <img
        src={top}
        sizes="100vw"
        style={{
          objectFit: "cover",
          width: "100%",
          height: 350,
          objectPosition: "50% 75%",
        }}
        alt=""
      />
      <div className="absolute inset-x-0 bottom-[40px] w-full px-[10%] py-2.5 text-center text-2xl lg:px-[20%]">
        <div className="grid grid-cols-12">
          <div className="col-span-3">
            <img
              className="m-3 ml-0 rounded-full"
              src={respireLogo}
              style={{
                objectFit: "contain",
                width: "100%",
                height: 250,
                objectPosition: "50% 50%",
              }}
              alt=""
            />
          </div>
          <div className="col-span-9">
            <img
              src={respire}
              style={{
                objectFit: "contain",
                width: "100%",
                height: 250,
                objectPosition: "50% 50%",
              }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
