import React from "react";
import { Link } from "react-router-dom";
import PublicLayout from "../components/layout/PublicLayout";
import KlangMap from "components/map/KlangMap";

function Home() {
  return (
    <>
      <div className="text-3xl">Klang District Air Quality Map</div>
      <KlangMap />
    </>
  );
}

export default Home;
