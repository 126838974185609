import {
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaLocationPin,
  FaPhone,
  FaYoutube,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export default function DoctorFooter() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  const contactUs = [
    {
      icon: <FaLocationPin fill="#ffffff" size={20} />,
      link: null,
      text: "Department of Primary Care Medicine, 26, Jalan Universiti, Pjs 11, Petaling Jaya, Malaysia",
    },
    {
      icon: <MdEmail fill="#ffffff" size={20} />,
      link: "mailto:respiremalaysia@gmail.com",
      text: "respiremalaysia@gmail.com",
    },
    {
      icon: <FaLocationPin fill="#ffffff" size={20} />,
      link: "https://um.edu.my/",
      text: "https://um.edu.my/",
    },
  ];
  const socials = [
    {
      icon: <FaFacebook fill="#ffffff" size={20} />,
      link: "https://www.facebook.com/RESPIREMalaysia/",
      text: "@RESPIREMalaysia",
    },
    {
      icon: <FaInstagram fill="#ffffff" size={20} />,
      link: "https://www.instagram.com/respiremalaysia/",
      text: "@RESPIREMalaysia",
    },
    {
      icon: <FaYoutube fill="#ffffff" size={20} />,
      link: "https://www.youtube.com/channel/UC3Ir5ucIrOf50h06yhh2cRA",
      text: "@RESPIREMalaysia",
    },
  ];
  const quickLinks = [
    {
      link: "/",
      text: "Privacy Policy",
    },
  ];

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  function renderContactUs() {
    return (
      <>
        <h1 className="py-6 text-center text-3xl font-bold text-white">
          Contact Us
        </h1>
        <div className="flex place-content-center pb-7">
          <div className="grid grid-cols-1">
            {contactUs.map((item, key) => (
              <div
                className="col-span-1 inline-flex items-center"
                key={`contact-us-${key}`}
              >
                <span className="p-2">{item.icon}</span>
                <a className="w-96 text-white" href={item.link} target="_blank">
                  {item.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  function renderSocials() {
    return (
      <>
        <h1 className="py-6 text-center text-3xl font-bold text-white">
          Socials
        </h1>
        <div className="flex place-content-center items-center pb-7">
          <div className="grid grid-cols-1">
            {socials.map((item, key) => (
              <div
                className="col-span-1 inline-flex items-center"
                key={`social-${key}`}
              >
                <span className="p-2">{item.icon}</span>
                <a className="text-white" href={item.link} target="_blank">
                  {item.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  function renderQuickLinks() {
    return (
      <>
        {quickLinks.map((item, key) => (
          <a
            className="text-white"
            href={item.link}
            target="_blank"
            key={`quick-link-${key}`}
          >
            {item.text}
          </a>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 bg-[#39729e] lg:grid-cols-2 ">
        <div className="">{renderContactUs()}</div>
        <div className="border-l-2 border-l-sky-800">{renderSocials()}</div>
      </div>
      <div className="flex place-content-center bg-sky-800 p-4">
        {renderQuickLinks()}
      </div>
    </>
  );
}
