const keyLabelPatientInfo = [
  {
    type: "text",
    key: "studyId",
    label: "Study ID",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "text",
    key: "name",
    label: "Name",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "number",
    key: "age",
    label: "Age",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "text",
    key: "mobile",
    label: "Mobile Number",
    size: 12,
    disable: false,
    required: true,
  },
  {
    type: "text",
    key: "email",
    label: "Email",
    size: 12,
    disable: false,
    required: true,
  },
];

export default {
  keyLabelPatientInfo,
};
