import React from "react";
import PrivacyPolicy from "components/about/privacyPolicy";
import PublicHeader from "components/header/PublicHeader";

function MobilePrivacyPolicy() {
  return (
    <div className="bg-none">
      <PublicHeader />
      <PrivacyPolicy isMobile={true} />
    </div>
  );
}

export default MobilePrivacyPolicy;
