import React from "react";
import { Card } from "react-daisyui";

function PrivacyPolicyComponent(props) {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [isMobile, setIsMobile] = React.useState(false);
  const [isMalay, setIsMalay] = React.useState(false);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  React.useEffect(() => {
    if (props.isMobile && props.isMobile === true) {
      setIsMobile(true);
    }
    if (props.isMalay && props.isMalay === true) {
      setIsMalay(true);
    }
  }, []);

  if (isMalay) {
    return (
      <>
        <Card className={`${isMobile ? "rounded-none" : ""}`}>
          <Card.Title className="py-2 px-10 border-b-2">
            <h1 className="text-center w-full text-3xl p-2">
              DASAR PRIVASI MENGENAI PENGGUNAAN APLIKASI
            </h1>
          </Card.Title>
          <Card.Body className="text-justify">
            <p>
              Dasar Privasi ini menerangkan cara kami menggunakan dan memproses
              maklumat yang diberikan oleh anda apabila anda menggunakan
              aplikasi RESPIRE AQA. Dasar Privasi ini berkaitan dengan data
              peribadi anda, peranti anda dan interaksi anda dengan perkhidmatan
              kami. Kami komited untuk menghormati privasi anda mengenai data
              peribadi yang anda kongsi dengan kami melalui penggunaan aplikasi
              RESPIRE AQA.
            </p>

            <ol>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Maklumat yang anda berikan kepada kami
                </h2>
                <ol className="text-base font-normal">
                  <li>
                    Jenis Maklumat Peribadi yang dikumpul merangkumi nama anda,
                    alamat e-mel, kata laluan, nombor telefon dan umur serta
                    keadaan kesihatan fizikal anda, khususnya mengenai gejala
                    asma harian. Maklumat yang diterima akan dinyahkenalpasti.
                  </li>
                  <li>
                    Kami tidak mengumpul data lokasi sebenar anda. Walau
                    bagaimanapun, dalam aplikasi kami, terdapat fungsi untuk
                    melaporkan sendiri lokasi anda, terhad hanya untuk bertanya
                    sama ada anda berada di dalam atau di luar kawasan Klang di
                    Malaysia, iaitu kawasan yang kami sediakan ramalan kualiti
                    udara.
                  </li>
                  <li>
                    Penyediaan data peribadi anda adalah secara sukarela. Anda
                    boleh menarik diri daripada menggunakan aplikasi pada
                    bila-bila masa. Walau bagaimanapun, jika anda tidak
                    memberikan kami data peribadi anda, kami tidak dapat
                    menawarkan perkhidmatan kami menggunakan aplikasi ini kepada
                    anda.
                  </li>
                </ol>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Cara kami menggunakan dan memproses data peribadi anda
                </h2>
                <div className="text-base font-normal">
                  <p className="mb-4">
                    Data peribadi yang anda berikan kepada kami akan dikaitkan
                    dengan profil anda. Secara automatic, kami menerima dan
                    merekodkan maklumat daripada pelayar anda ke dalam log
                    pelayan kami, termasuk alamat IP anda, maklumat kuki, dan
                    halaman yang anda minta.
                  </p>
                  <p>
                    Secara amnya, perkhidmatan kami secara automatik mengumpul
                    maklumat penggunaan seperti bilangan dan kekerapan pelawat
                    menggunakan perkhidmatan kami, komponennya, cara anda
                    berinteraksi dan menggunakan perkhidmatan. Identiti peribadi
                    anda tidak akan dikenalpasti dengan cara penggunaan data
                    kami. Data ini membolehkan kami menganalisa cara anda
                    menggunakan bahagian perkhidmatan supaya kami boleh menambah
                    baik perkhidmatan tersebut.
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">Keselamatan data peribadi anda</h2>
                <ol className="text-base font-normal">
                  <li>
                    Kami akan menggunakan perlindungan pentadbiran, fizikal dan
                    teknikal untuk melindungi keselamatan dan privasi data
                    peribadi anda yang didedahkan kepada kami melalui aplikasi
                    ini daripada sebarang kehilangan, kecurian, penggunaan tanpa
                    kebenaran atau pendedahan atau pengubahsuaian.
                  </li>
                  <li>
                    Data peribadi anda akan disimpan dalam pelayan data selamat
                    yang dilindungi daripada akses tanpa kebenaran. Pelayan data
                    mempunyai dasar dan prosedur untuk melindungi data peribadi
                    yang disimpan pada pelayan. Akses kepada data yang disimpan
                    pada pelayan kami adalah terhad kepada kakitangan yang
                    diberi kuasa sahaja. Pelayan data mungkin terletak di luar
                    Malaysia, bermakna data peribadi anda boleh dipindahkan ke
                    negara lain. Dengan menggunakan perkhidmatan kami, anda
                    bersetuju dengan kemungkinan pemindahan data peribadi anda
                    ini.
                  </li>
                </ol>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Akses, kemas kini dan pengubahsuaian kepada data peribadi anda
                </h2>
                <div className="text-base font-normal">
                  <p>
                    Jika anda ingin mengakses, bertanya, menyemak, meminda,
                    membetulkan, menyekat, atau meminta salinan atau memadam
                    data peribadi tentang anda atau meminta kami berhenti
                    mengumpul, memproses atau menggunakannya seperti yang
                    dibenarkan oleh undang-undang yang berkenaan, anda boleh
                    menghubungi kami di alamat e-mel yang disediakan di bawah.
                    Dalam keadaan tertentu, kami mungkin meminta anda
                    mengesahkan identiti anda sebelum permintaan anda diproses.
                    Ini akan dilakukan secara percuma kecuali jika ia memerlukan
                    usaha yang tidak seimbang. Kami mungkin menolak permintaan
                    yang tidak munasabah, yang memerlukan usaha teknikal yang
                    tidak seimbang (contohnya, membangunkan sistem baharu atau
                    secara asasnya mengubah amalan sedia ada), mendedahkan
                    privasi orang lain, atau akan menjadi sangat tidak praktikal
                    (contohnya, permintaan mengenai maklumat yang berada pada
                    sandaran pita).
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">Pengekalan data peribadi anda</h2>
                <div className="text-base font-normal">
                  <p>
                    Data peribadi anda akan disimpan untuk tempoh lima tahun.
                    Selepas itu, kami akan mengambil semua langkah yang
                    diperlukan untuk memastikan data peribadi anda dipadam
                    secara kekal.
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Data tanpa nama dikongsi dengan pihak ketiga
                </h2>
                <div className="text-base font-normal">
                  <p>
                    Kami telah menggunakan “Firebase” dalam aplikasi kami,
                    terutamanya untuk analisa pengguna dan notifikasi
                    pemberitahuan. Data yang dikumpul melalui “Firebase” adalah
                    tanpa nama dan tidak mengandungi sebarang maklumat yang
                    boleh dikenal pasti secara peribadi. Data tanpa nama ini
                    mungkin dikongsi dengan penyedia perkhidmatan pihak ketiga
                    untuk membantu kami meningkatkan prestasi aplikasi kami,
                    meningkatkan pengalaman pengguna dan menyampaikan
                    pemberitahuan yang berkaitan. Pembekal pihak ketiga ini
                    bertanggungjawab untuk mematuhi dasar perlindungan data yang
                    ketat dan hanya dibenarkan untuk menggunakan data bagi
                    tujuan yang ditentukan oleh kami. Kami tidak menjual atau
                    berkongsi data peribadi untuk tujuan pemasaran tanpa
                    kebenaran yang jelas. Selain “Firebase”, semua perkhidmatan
                    atau ciri lain yang berinteraksi dengan pengguna dalam
                    aplikasi kami disediakan oleh kami dan semua data akan
                    disimpan dalam pangkalan data kami. Kami mengambil langkah
                    yang ketat untuk memastikan keselamatan dan kerahsiaan data
                    dengan melaksanakan kawalan akses dan enkripsi yang kukuh.
                    Kami tidak berkongsi data ini dengan pihak ketiga kecuali
                    seperti yang dikehendaki oleh undang-undang atau untuk
                    melindungi hak, harta benda atau keselamatan pengguna kami
                    dan aplikasi kami. Untuk mendapatkan maklumat lanjut tentang
                    Dasar Privasi “Firebase” boleh didapati di{" "}
                    <a
                      href="https://firebase.google.com/support/privacy/dpo"
                      className="text-blue-500 hover:underline"
                    >
                      https://firebase.google.com/support/privacy/dpo
                    </a>
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">Hubungi kami</h2>
                <div className="text-base font-normal">
                  <p>
                    Jika anda ingin menghubungi kami mengenai Dasar Privasi ini,
                    sila hantar e-mel kepada:{" "}
                    <a
                      className="text-blue-500"
                      href="mailto:respiremalaysia@gmail.com"
                    >
                      respiremalaysia@gmail.com
                    </a>
                  </p>
                </div>
              </li>
            </ol>
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return (
      <>
        <Card className={`${isMobile ? "rounded-none" : ""}`}>
          <Card.Title className="py-2 px-10 border-b-2">
            <h1 className="text-center w-full text-3xl p-2">
              PRIVACY POLICY ON THE USE OF THE APPLICATION
            </h1>
          </Card.Title>
          <Card.Body className="text-justify">
            <p>
              This Privacy Policy explains how we use and process the
              information provided by you when you use the RESPIRE AQA
              application. This Privacy Policy relates to your personal data,
              your devices and your interaction with our services. We are
              committed to respecting your privacy about the personal data that
              you share with us through your use of the RESPIRE AQA application.
            </p>

            <ol>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Information that you provide to us
                </h2>
                <ol className="text-base font-normal">
                  <li>
                    The types of Personal Information that is collected include
                    your name, email address, password, phone number, age, and
                    your physical health condition, specifically on the daily
                    asthma symptoms. The information captured will be
                    de-identified.
                  </li>
                  <li>
                    We do not collect your actual location data. However, in our
                    application, there is a function to self-report your
                    location, limited only to asking whether you are inside or
                    outside the Klang area in Malaysia, which is the area which
                    we provide the air quality forecast.
                  </li>
                  <li>
                    The provision of your personal data is on a voluntary basis.
                    You can opt out from using the application at any time.
                    However, if you do not provide us with your personal data,
                    we will not be able to provide you with our service using
                    this application.
                  </li>
                </ol>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  How we use and process your personal data
                </h2>
                <div className="text-base font-normal">
                  <p className="mb-4">
                    The personal data that you provide to us will be associated
                    with your profile. We automatically receive and record
                    information from your browser on our server logs, including
                    your IP address, cookie information, and the page you have
                    requested.
                  </p>
                  <p>
                    Generally, our Services automatically collect usage
                    information such as the number and frequency of visitors to
                    our Services, and its components, and how you interact with
                    and use the Services. We only use this data in a manner that
                    would not identify you personally. This data enables us to
                    figure out how you use parts of the Services so that we can
                    make the Services useful for as many users as possible and
                    improve upon those Services.
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">Security of your personal data</h2>
                <ol className="text-base font-normal">
                  <li>
                    We will use administrative, physical, and technical
                    safeguards to protect the security and privacy of your
                    personal data disclosed to us through this application
                    against any loss, theft, unauthorized use, or disclosure or
                    modification.
                  </li>
                  <li>
                    Your personal data will be kept in a secured data server(s)
                    that is protected from unauthorized access. The data
                    server(s) have policies and procedures in place to safeguard
                    the personal data that is stored on the server(s). Access to
                    the data stored on our servers is limited to authorized
                    personnel only. The data servers might be located outside of
                    Malaysia, so your personal data could be transferred to
                    another country. By using our services, you agree to this
                    potential transfer of your personal data.
                  </li>
                </ol>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Access, update and modification to your personal data
                </h2>
                <div className="text-base font-normal">
                  <p>
                    If you wish to access, inquire, review, amend, correct,
                    suppress, or request a copy of or delete personal data about
                    you or request that we cease collecting, processing, or
                    using it as permitted by applicable law, you can contact us
                    at the email address provided below. Under certain
                    circumstances, we may ask you to verify your identity before
                    your request is processed. This will be done free of charge
                    except where it would require a disproportionate effort. We
                    may reject requests that are unreasonably repetitive,
                    require disproportionate technical effort (for example,
                    developing a new system or fundamentally changing an
                    existing practice), risk the privacy of others, or would be
                    extremely impractical (for instance, requests concerning
                    information residing on backup tapes).
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Access, update and modification to your personal data
                </h2>
                <div className="text-base font-normal">
                  <p>
                    Your personal data will be retained for a duration of five
                    years. After that, we will take all necessary steps to
                    ensure that your personal data is permanently deleted.
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">
                  Anonymous data shared with third parties
                </h2>
                <div className="text-base font-normal">
                  <p>
                    We have used Firebase in our app, primarily for user
                    analytics and push notifications. The data collected through
                    Firebase is anonymized and does not contain any personally
                    identifiable information. This anonymous data may be shared
                    with third-party service providers to help us improve our
                    app's performance, enhance user experience, and deliver
                    relevant notifications. These third-party providers are
                    obligated to adhere to strict data protection policies and
                    are only permitted to use the data for the purposes
                    specified by us. We do not sell or share personal data for
                    marketing purposes without explicit consent. Besides
                    Firebase, all other services or features that users interact
                    with in our app are provided by us, and all data will be
                    stored in our database. We take stringent measures to ensure
                    the security and confidentiality of the data, implementing
                    robust encryption and access controls. We do not share this
                    data with third parties except as required by law or to
                    protect the rights, property, or safety of our users and our
                    app. For more info about Firebase Privacy Policy can be
                    found at{" "}
                    <a
                      href="https://firebase.google.com/support/privacy/dpo"
                      className="text-blue-500 hover:underline"
                    >
                      https://firebase.google.com/support/privacy/dpo
                    </a>
                  </p>
                </div>
              </li>
              <li className="text-xl font-semibold">
                <h2 className="mt-6 mb-3">Contacting us</h2>
                <div className="text-base font-normal">
                  <p>
                    If you would like to contact us about this Privacy Policy,
                    please send an e-mail to:{" "}
                    <a
                      className="text-blue-500"
                      href="mailto:respiremalaysia@gmail.com"
                    >
                      respiremalaysia@gmail.com
                    </a>
                  </p>
                </div>
              </li>
            </ol>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default PrivacyPolicyComponent;
