import { httpRequest } from "helpers/httpClient/httpClient";
import CONST from "constants/constant";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

/************************************************************************************/
// CONST
/************************************************************************************/

/************************************************************************************/
// FUNCTIONS
/************************************************************************************/

// export function checkSession(layoutType) {
export function checkSession(redirectFunction) {
  try {
    var userObject = JSON.parse(localStorage.getItem("userObject"));
    var email = userObject.email;
    var token = userObject.token;
  } catch (e) {
    toast("Session Invalid");
    return redirectFunction();
  }

  httpRequest(
    "/auth/session",
    "POST",
    {
      email: email,
      token: token,
    },
    doCheckingCB.bind(this)
  );

  function doCheckingCB(jsonObj) {
    if (jsonObj.success === true) {
      // Check for permission to access the page
      // if (
      //   jsonObj.data.roles &&
      //   jsonObj.data.roles.length &&
      //   !jsonObj.data.roles.includes(CONST[layoutType])
      // ) {
      //   try {
      //     console.log("Page Access Permission Denied");
      //     Router.push("/auth/login");
      //   } catch (e) {}
      // }
      jsonObj.data.token = jsonObj.data.token[0];
      localStorage.setItem("userObject", JSON.stringify(jsonObj.data));
    } else {
      try {
        redirectFunction();
      } catch (e) {}
    }
  }
}

export function getSixDigitRandom() {
  return Math.random().toString().substring(2, 8);
}

export function htmlDecode(input) {
  if (input.includes("&lt;")) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  } else {
    return input;
  }
}

export function checkServiceValue(services, service, module) {
  if (!services || !service || !module) return false;

  var serviceI = services.findIndex((x) => x.service === service);
  if (serviceI === -1) return false;
  if (!services[serviceI].modules) return false;

  var moduleI = services[serviceI].modules.findIndex((x) => x === module);
  if (moduleI === -1) return false;

  return true;
}

export function prettyDate(time) {
  if (!time) {
    return "-";
  }

  var date = new Date(
      Number(time.toString().replace(/-/g, "/").replace(/[TZ]/g, " "))
    ),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    day_diff = Math.floor(diff / 86400);

  if (isNaN(day_diff) || day_diff < 0) return;

  return (
    (day_diff === 0 &&
      ((diff < 60 && "just now") ||
        (diff < 120 && "1 minute ago") ||
        (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
        (diff < 7200 && "1 hour ago") ||
        (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
    (day_diff === 1 && "Yesterday") ||
    (day_diff < 7 && day_diff + " days ago") ||
    Math.ceil(day_diff / 7) + " weeks ago"
  );
}

export function getYoutubeVideoId(link) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = link.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
}

export async function copyToClipboard(text) {
  try {
    const permissions = await navigator.permissions.query({
      name: "clipboard-write",
    });
    if (permissions.state === "granted" || permissions.state === "prompt") {
      await navigator.clipboard.writeText(text);
      toast("Text Copied to Clipboard!");
    } else {
      throw new Error(
        "Can't access the clipboard. Check your browser permissions."
      );
    }
  } catch (error) {
    toast("Error copying to clipboard:", error);
  }
}

export function addHours(date, hours) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  date.setTime(date.getTime() + hoursToAdd);
  return date;
}

export function addDays(date, days) {
  const daysToAdd = days * 24 * 60 * 60 * 1000;
  date.setTime(date.getTime() + daysToAdd);
  return date;
}

export function getDay(date, short, isMalay) {
  var day = date.getDay();

  if (isMalay) {
    switch (day) {
      case 0:
        return short ? "Isn" : "Isnin";
      case 1:
        return short ? "Sel" : "Selasa";
      case 2:
        return short ? "Rabu" : "Rabu";
      case 3:
        return short ? "Kha" : "Khamis";
      case 4:
        return short ? "Jum" : "Jumaat";
      case 5:
        return short ? "Sab" : "Sabtu";
      case 6:
        return short ? "Ahd" : "Ahad";
      default:
        return "Date error";
    }
  }

  switch (day) {
    case 0:
      return short ? "Mon" : "Monday";
    case 1:
      return short ? "Tue" : "Tuesday";
    case 2:
      return short ? "Wed" : "Wednesday";
    case 3:
      return short ? "Thu" : "Thursday";
    case 4:
      return short ? "Fri" : "Friday";
    case 5:
      return short ? "Sat" : "Saturday";
    case 6:
      return short ? "Sun" : "Sunday";
    default:
      return "Date error";
  }
}

export function getFullDate(date) {
  var year = date.getFullYear();
  var month = String(date.getMonth()).padStart(2, "0");
  var day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
}

export function getNextHour(date) {
  // date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setHours(date.getHours() + 1);
  date.setMinutes(0, 0, 0);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (hours == "00" || hours == "24") {
    return "0100";
  }
  console.log(`${hours}${minutes}`);
  return `${hours}${minutes}`;
}
