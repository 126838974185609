import top from "../../images/top.jpg";
import respire from "../../images/respire.png";
import respireLogo from "../../images/respire-logo.png";

import { Row, Col } from "components/CustomComponents";
import React, { useState } from "react";
import { Avatar, Button, Dropdown, Menu } from "react-daisyui";
import { FaBook, FaPerson } from "react-icons/fa6";
import {
  IoBusiness,
  IoHomeOutline,
  IoLogOutOutline,
  IoNotificationsOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

export default function DoctorHeader() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/
  const [userObj, setUserObj] = React.useState(null);

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  return (
    <div className="relative">
      <img
        src={top}
        sizes="100vw"
        style={{
          objectFit: "cover",
          width: "100%",
          height: 350,
          objectPosition: "50% 75%",
        }}
        alt=""
      />
      <div className="absolute inset-x-0 bottom-[40px] w-full px-[10%] py-2.5 text-center text-2xl lg:px-[20%]">
        <div className="grid grid-cols-12">
          <div className="col-span-3">
            <img
              className="m-3 ml-0 rounded-full"
              src={respireLogo}
              style={{
                objectFit: "contain",
                width: "100%",
                height: 250,
                objectPosition: "50% 50%",
              }}
              alt=""
            />
          </div>
          <div className="col-span-9">
            <img
              src={respire}
              style={{
                objectFit: "contain",
                width: "100%",
                height: 250,
                objectPosition: "50% 50%",
              }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
