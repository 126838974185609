import React from "react";
import KlangMap from "components/map/KlangMap";

function MobileMapMalay() {
  return (
    <div className="bg-none">
      <KlangMap isMobile={true} isMalay={true} />
    </div>
  );
}

export default MobileMapMalay;
