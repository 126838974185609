import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import Home from "./pages/Home";
import Login from "./pages/auth/login";
import PublicLayout from "components/layout/PublicLayout";
import DoctorLayout from "components/layout/DoctorLayout";
import NotFound from "pages/NotFound";
import ListPatient from "pages/doctor/patient/listPatient";
import MobileMap from "pages/mobile/Map";
import MobileMapMalay from "pages/mobile/MapMalay";
import PrivacyPolicy from "pages/public/about/privacyPolicy";
import MobilePrivacyPolicyMalay from "pages/mobile/about/privacyPolicyMalay";
import MobilePrivacyPolicy from "pages/mobile/about/privacyPolicy";

function App() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/

  /***************************************************************************************/
  //Var
  /***************************************************************************************/
  TimeAgo.addLocale(en);

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  return (
    <div className="w-full h-screen bg-bg-klang bg-no-repeat bg-center bg-cover">
      <div className="bg-gray-400 w-full h-full bg-opacity-60 overflow-y-scroll">
        <Router>
          <div className="h-full">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/" element={<PublicLayout />}>
                <Route path="home" element={<Home />} />
              </Route>

              {/* About Routes */}
              <Route
                path="about"
                element={<Navigate to="/about/privacyPolicy" replace />}
              />
              <Route path="/about" element={<PublicLayout />}>
                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
              </Route>

              {/* Authentication Routes */}
              <Route path="/auth/login" element={<Login />} />

              {/* Doctor Routes */}
              <Route
                path="doctor"
                element={<Navigate to="/doctor/patient/list" replace />}
              />
              <Route path="/doctor" element={<DoctorLayout />}>
                <Route
                  path="patient"
                  element={<Navigate to="/doctor/patient/list" replace />}
                />
                <Route path="patient">
                  <Route path="list" element={<ListPatient />} />
                </Route>
              </Route>

              {/* Mobile Routes */}
              <Route
                path="mobile"
                element={<Navigate to="/mobile/map" replace />}
              />
              <Route path="mobile">
                <Route path="map" element={<MobileMap />} />
                <Route path="mapMalay" element={<MobileMapMalay />} />
                <Route path="about">
                  <Route
                    path="privacyPolicy"
                    element={<MobilePrivacyPolicy />}
                  />
                  <Route
                    path="privacyPolicyMalay"
                    element={<MobilePrivacyPolicyMalay />}
                  />
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Router>
      </div>

      <Toaster />
    </div>
  );
}

export default App;
