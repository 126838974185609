import React from "react";
import PrivacyPolicyComponent from "components/about/privacyPolicy";

function PrivacyPolicy() {
  /***************************************************************************************/
  //States
  /***************************************************************************************/

  /***************************************************************************************/
  //Var
  /***************************************************************************************/

  /***************************************************************************************/
  //Callbacks
  /***************************************************************************************/
  return (
    <>
      <div className="p-2">
        <PrivacyPolicyComponent />
      </div>
    </>
  );
}

export default PrivacyPolicy;
