import React from "react";
import PrivacyPolicy from "components/about/privacyPolicy";
import PublicHeader from "components/header/PublicHeader";

function MobilePrivacyPolicyMalay() {
  return (
    <div className="bg-none">
      <PublicHeader />
      <PrivacyPolicy isMobile={true} isMalay={true} />
    </div>
  );
}

export default MobilePrivacyPolicyMalay;
